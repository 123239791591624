import * as React from 'react';
import Layout from '../../components/layout';
import Article from '../../components/article';
import { Seo } from '../../components/seo';
import Quote from '../../components/quote';
import { StaticImage } from 'gatsby-plugin-image';

const FreelanceWebDevelopmentPricing = ({ location }) => {
  const pageTitle =
    'Freelance Web Development Pricing: Rates, Estimating Projects, and Turning a Profit';
  const gavinSimon = (
    <StaticImage src="./images/gavin.jpg" alt="Gavin Simon photo" />
  );
  return (
    <Layout pageType="landing" pageTitle={pageTitle}>
      <Seo
        pageTitle={pageTitle}
        description="This book will teach you to price your freelance web development services and estimate projects."
        pathname={location.pathname}
      />
      <Article>
        <Quote
          photo={gavinSimon}
          attribution="Gavin Simon, aspiring freelancer">
          Packed with solid information and straight to the point.
        </Quote>
        <h1 className="fancy">
          The Freelance Web Dev&shy;elop&shy;ment Pricing Ebook
        </h1>
        <StaticImage
          src="./images/freelance-web-development-pricing-cover.png"
          alt="Freelance Web Development Pricing ebook cover"
          className="f-left"
          width="200"
          style={{ margin: '0 1em 1em 0' }}
        />
        <p>
          Freelancing comes with a lot of uncertainty. <b>Pricing your work</b>{' '}
          and <b>estimating projects</b> are two of the most difficult skills to
          develop starting out. They also come with the highest stakes: if you
          screw up, <b>you lose money</b>!
        </p>
        <a
          className="button -large -cta"
          href="https://dashboard.mailerlite.com/forms/90414/62182106203162218/share">
          Try a free chapter
        </a>
        <p className="ta-center">
          <small>
            <a href="https://raddevon.gumroad.com/l/freelance-web-development-pricing">
              or buy a copy
            </a>
          </small>
          .
        </p>
        <p style={{ clear: 'both' }}>
          <cite>Freelance Web Development Pricing</cite>{' '}
          <strong>gives you confidence</strong> amidst all the uncertainty.
          You’ll learn to:
        </p>
        <ol>
          <li>
            Find your <strong>minimum hourly rate</strong>. You’ll probably
            never charge this rate, but you’ll know the minimum you need to
            charge to stay afloat.
          </li>
          <li>
            <strong>Make a profit</strong> on every gig. Profit allows you to do
            more than just keep going. It allows you to grow, whether that means
            reaching bigger customers or bringing on help to do things you can’t
            do or don’t enjoy.
          </li>
          <li>
            <strong>Bid with confidence</strong>. There’s always at least a hint
            of uncertainty when you quote a price. This book will show you how
            to support your bid and deliver it with minimal doubt.
          </li>
          <li>
            <strong>Handle pushback</strong> from prospective clients. Clients
            want to know they’re getting good value for their money. You’ll
            learn how to prove that you’re worth it.
          </li>
        </ol>
        <h2>Detailed Outline</h2>
        <p>
          Here’s a more detailed look at what you’ll be getting in the book.
        </p>
        <h3>Part 1: Freelance Rates 💰</h3>
        <ul>
          <li>
            Pricing Is Scary
            <ul>
              <li>
                Things You May Not Know
                <ul>
                  <li>How Many Hours You’ll Actually Bill</li>
                  <li>
                    Things You’ll Have to Pay For (That You Might Not Have
                    Considered)
                    <ul>
                      <li>Taxes</li>
                      <li>Vacation &amp; Holiday Pay</li>
                      <li>Retirement Savings</li>
                      <li>Health Insurance</li>
                      <li>Life Insurance</li>
                      <li>Other Benefits</li>
                      <li>Your Tools</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Find Your Ideal Hourly Rate
            <ul>
              <li>
                Basis for Your Rate
                <ul>
                  <li>Example Calculation</li>
                  <li>What If My Expenses Are Low?</li>
                </ul>
              </li>
              <li>The Magic Ideal Rate Formula</li>
            </ul>
          </li>
          <li>
            Ways to Price Your Services
            <ul>
              <li>
                Pricing By Time- Hourly, Daily, or Weekly Rates
                <ul>
                  <li>Advantages</li>
                  <li>Disadvantages</li>
                </ul>
              </li>
              <li>
                Project-Based Pricing
                <ul>
                  <li>Advantages</li>
                  <li>Disadvantages</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            The Holy Grail of Pricing
            <ul>
              <li>
                Outputs over Inputs
                <ul>
                  <li>
                    Implement Pricing Based on Outputs
                    <ul>
                      <li>The Cost of the Problem</li>
                      <li>The Impact of the Solution</li>
                      <li>Putting It All Together</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Talking to Clients
            <ul>
              <li>Understanding Your Value</li>
              <li>
                Proving Your Value
                <ul>
                  <li>Lean on History</li>
                  <li>Show Proof</li>
                  <li>Let Them Set the Value</li>
                </ul>
              </li>
              <li>
                Negotiating
                <ul>
                  <li>Mindset</li>
                  <li>Strategies</li>
                </ul>
              </li>
              <li>Landmines and Red Flags</li>
            </ul>
          </li>
          <li>
            Freelance Rate Roadmap
            <ul>
              <li>Where to Start</li>
              <li>Increasing Your Rate</li>
              <li>Transitioning to Output-Based Pricing</li>
            </ul>
          </li>
        </ul>
        <h3>Part 2: Estimating Projects 🕔</h3>
        <ul>
          <li>
            Why Estimate?
            <ul>
              <li>
                So You Can Tell Your Client When Their Project Will Be Done
              </li>
              <li>So You Can Make Sure Your Pricing Makes Sense</li>
              <li>So You Can Get Better at Estimating</li>
            </ul>
          </li>
          <li>
            Breaking Down the Project
            <ul>
              <li>
                How to Break It Down
                <ul>
                  <li>
                    Project: Build a pricing calculator for a customizable
                    product
                  </li>
                  <li>Project: Help us launch a new product on our web site</li>
                </ul>
              </li>
              <li>How to Know When You’re Done</li>
            </ul>
          </li>
          <li>
            Estimating Each Part
            <ul>
              <li>Draw On Your Experience</li>
              <li>Estimating Tasks When You Have No Experience</li>
              <li>
                A Complete Estimate
                <ul>
                  <li>Project: Help us launch a new product on our web site</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Things You’ll Have to Estimate For
            <ul>
              <li>Communication</li>
              <li>Travel</li>
              <li>Revisions</li>
              <li>Expenses</li>
              <li>Your Optimism</li>
            </ul>
          </li>
          <li>
            Billing for Your Estimate
            <ul>
              <li>Why Charge for an Estimate?</li>
              <li>Why Would the Client Pay?</li>
              <li>How to Execute Your Estimate</li>
              <li>How to Sell It</li>
            </ul>
          </li>
        </ul>
        <a
          className="button -large -cta"
          href="https://dashboard.mailerlite.com/forms/90414/62182106203162218/share">
          Try a free chapter
        </a>
        <p className="ta-center">
          <small>
            <a href="https://raddevon.gumroad.com/l/freelance-web-development-pricing">
              or buy a copy
            </a>
          </small>
          .
        </p>
        <h2>FAQs</h2>
        <dl>
          <dt>Who is the book for?</dt>
          <dd>
            <p>
              The book is for brand new freelancers, established freelancers who
              have trouble with pricing, or freelancers looking for ways to
              increase their profit margins.
            </p>
            <p>
              It’s written specifically for web developers, but other
              freelancers will still find most of the book useful.
            </p>
          </dd>
          <dt>How do I know this will work for me?</dt>
          <dd>
            <p>
              What this book <strong>will definitely do</strong> is give you a
              new perspective: the methods I’ve used to price and estimate.
              Learn what you can from my experience. Try my methods and, if they
              don’t work, cherry-pick what you like from them.
            </p>
            <p>
              The developer interviews included with the deluxe package will
              give you some additional perspectives you can mix into the stew
              that will become your own method of pricing and estimating
              projects.
            </p>
          </dd>
          <dt>Will this book still be useful if I'm not in the US?</dt>
          <dd>
            <p>
              Most of the book is applicable wherever you operate your business.
              Specific rate recommendations are geared toward US freelance web
              developers.
            </p>
            <p>
              The contract included with the Deluxe package has been reviewed by
              a lawyer in the US.
            </p>
          </dd>
          <dt>What if I receive the book and don't like it?</dt>
          <dd>
            <p>
              If you don’t like the book for any reason, email me at{' '}
              <a href="mailto:devon@raddevon.com">devon@raddevon.com</a> for a
              full refund.
            </p>
          </dd>
        </dl>
      </Article>
    </Layout>
  );
};
export default FreelanceWebDevelopmentPricing;
